import {
  ReactIntegration,
  getWebInstrumentations,
  initializeFaro,
} from "@grafana/faro-react";

import useMonitoring from "./hooks/useMonitoring";

const initializeFaroMonitoring = () => {
  if (useMonitoring() && typeof window !== "undefined") {
    initializeFaro({
      url: process.env.GATSBY_MONITORING_ENDPOINT,
      app: {
        name: "Rightio Landing",
        version: process.env.GATSBY_MONITORING_VERSION,
        environment: process.env.GATSBY_MONITORING_ENVIRONMENT,
      },
      instrumentations: [...getWebInstrumentations(), new ReactIntegration()],
      ignoreErrors: [
        /(Minified React error #418)/,
        /(Minified React error #423)/,
        /(Minified React error #425)/,
      ],
    });
  }
};
export { initializeFaroMonitoring };

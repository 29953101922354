import "/src/css/main.css";

import React, { useEffect } from "react";
import { faro, withFaroErrorBoundary } from "@grafana/faro-react";
import { initializeCustomDispatch, useIsHome } from "../../hooks/useIsHome";

import { initializeFaroMonitoring } from "../../monitoring";

// import "/src/css/slick.css";
// import "/src/css/slick-theme.css";

/**
 * A high-level layout component that sets up monitoring and custom dispatch.
 *
 * @param {Object} children - The child components to be rendered.
 * @return {JSX.Element} The rendered child components.
 */
const Layout = ({ children }: { children: React.ReactNode }) => {
  // Set up monitoring if it is not already set up
  if (typeof faro.api === "undefined") initializeFaroMonitoring();

  // Set up custom dispatch so the custom dispatch can be
  // called in gatsby-browser.js
  const context = useIsHome();

  // Initialize custom dispatch when context is available
  useEffect(() => {
    if (context) initializeCustomDispatch(context.customDispatch);
  }, [context]);

  // Render children
  return children;
};

// export default Layout;
export default withFaroErrorBoundary(Layout, {});
